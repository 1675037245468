import React from 'react'

interface OopsProps {
  oopsText: string
  tryAnotherText: string
}

export const Oops: React.FC<OopsProps> = ({ oopsText, tryAnotherText }) => (
  <div className="text-center">
    <p data-testid="oops" className="py-4 text-3xl">
      {oopsText}
    </p>
    <p data-testid="try-another" className="py-4 text-xl">
      {tryAnotherText}
    </p>
  </div>
)
