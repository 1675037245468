import React from 'react'
import { paths } from '@/constants'
import { useTranslate } from '@/utils/translate/translate-client'
import { ShowHero } from '../ShowHero'

export const FeaturedShow: React.FC = () => {
  const { t } = useTranslate('common')

  const cloudinaryImgPaths = {
    mobile:
      'https://images.angelstudios.com/image/upload/v1731958173/studio-app/catalog/d5eec788-2226-45e4-8156-326d68e07129',
    tablet:
      'https://images.angelstudios.com/image/upload/v1731958153/studio-app/catalog/0ffba4ea-af1e-4555-b589-59d21c27bd7e',
    desktop:
      'https://images.angelstudios.com/image/upload/v1731958145/studio-app/catalog/024fa80e-bb70-4de0-ae90-ff6f1e287555',
  }

  return (
    <ShowHero
      cloudinaryImgPaths={cloudinaryImgPaths}
      imageClassName="rounded-lg bg-cover"
      showTitle={t('riotDanceTitle', 'The Riot and the Dance', { ns: 'common' })}
      showDescription={t(
        'riotDanceFeaturedDescription',
        'Discover the beauty of creation through awe-inspiring journeys with Dr. Gordon Wilson.',
      )}
      ctaPath={paths.watch.riotAndTheDanceSeries}
      ctaText={t('watchNow', 'Watch Now', { ns: 'common' })}
    />
  )
}
