import React, { useEffect, useRef } from 'react'
import { useRouter } from 'next/router'
import { Oops } from '@/atoms/Oops'
import { PaddedContainer } from '@/atoms/PaddedContainer'
import { Seo } from '@/layout/Seo'
import { useCanonicalUrl } from '@/layout/Seo/useCanonicalUrl'
import { useSafeTrack } from '@/utils/analytics'
import { logger } from '@/utils/logging'
import { useTranslate } from '@/utils/translate/translate-client'
import { FeaturedShowSection } from '@/views/LandingView/FeaturedShowSection'

export const NotFoundView = () => {
  const { t } = useTranslate('common')
  const track = useSafeTrack()
  const hasTrackedRef = useRef<string>()
  const { asPath } = useRouter()

  const oopsText = t('404GenericOops', "Oops, we couldn't find this page.")
  const tryAnotherText = t(
    '404GenericTryAnotherText',
    'Try another link or check out one of our record-shattering stories.',
  )

  const url = useCanonicalUrl(`/404`)

  useEffect(() => {
    if (hasTrackedRef.current !== asPath) {
      track('404 Viewed', {
        path: asPath,
      })
      logger().warn('404 viewed path', { status: 404, asPath })
      hasTrackedRef.current = asPath
    }
  }, [hasTrackedRef, track, asPath])

  return (
    <>
      <Seo
        canonical={url}
        description={oopsText}
        noindex
        nofollow
        openGraph={{ title: oopsText, description: oopsText, url }}
        title={oopsText}
      />
      <div className="bg-core-gray-950 text-white">
        <PaddedContainer className="bg-core-gray-950 pt-28">
          <Oops oopsText={oopsText} tryAnotherText={tryAnotherText} />
          <FeaturedShowSection />
        </PaddedContainer>
      </div>
    </>
  )
}
