import React from 'react'
import classNames from 'classnames'
import { LinkButton } from '@/atoms/Button'
import { WatchTriangleIcon } from '@/atoms/Icons/WatchTriangleIcon'
import { Image } from '@/atoms/Image'
import { PaddedContainer } from '@/atoms/PaddedContainer'
import { ResponsiveBackgroundImage } from '@/atoms/ResponsiveBackgroundImage'
import { AsLabel, ParagraphLG, TitleXS } from '@/atoms/Text'
import { scaleDimensions } from '@/atoms/utils'
import { getCloudinaryImageUrl } from '@/utils/Cloudinary'

interface CloudinaryImagePaths {
  mobile: string
  tablet: string
  desktop: string
}
export interface ShowHeroProps {
  cloudinaryImgPaths: CloudinaryImagePaths
  imageClassName?: string
  showTitle: string
  showDescription: string
  ctaPath: string
  ctaText: string
  ctaClassName?: string
  imageContainerClassName?: string
}

export const ShowHero: React.FC<ShowHeroProps> = ({
  cloudinaryImgPaths,
  imageClassName,
  showTitle,
  showDescription,
  ctaPath,
  ctaText,
}) => {
  const { width: w, height: h } = scaleDimensions(110, 90, 32)
  const words = (
    <div className="w-full">
      <header>
        <Image
          width={w}
          height={h}
          src={getCloudinaryImageUrl({
            path: '/v1667843112/angel-app/riot-and-dance/Riot_and_the_Dance_2x.webp',
            transforms: 'e_trim',
          })}
          className={'mx-auto mb-4 lg:mx-0'}
          alt={showTitle}
        />
      </header>
      <ParagraphLG color="core-gray-300" className="mb-10 text-center lg:max-w-[50%] lg:text-left">
        {showDescription}
      </ParagraphLG>
      <div className="flex w-full justify-center lg:justify-start">
        <LinkButton
          data-testid="watch-now-button"
          className="flex max-w-[220px] items-center justify-center space-x-3.5 px-12 py-4"
          href={ctaPath}
          variant="white"
        >
          <TitleXS weight="bold" as={AsLabel} className="cursor-pointer whitespace-nowrap" color="black">
            {ctaText}
          </TitleXS>
          <WatchTriangleIcon />
        </LinkButton>
      </div>
    </div>
  )

  return (
    <>
      <div className={classNames('relative w-full mb-6 lg:mb-0 !min-h-[320px] lg:!min-h-[600px]')}>
        <ResponsiveBackgroundImage
          src={cloudinaryImgPaths.desktop}
          srcUrls={{
            xs: cloudinaryImgPaths.mobile,
            sm: cloudinaryImgPaths.mobile,
            md: cloudinaryImgPaths.tablet,
            lg: cloudinaryImgPaths.desktop,
            xl: cloudinaryImgPaths.desktop,
            '2xl': cloudinaryImgPaths.desktop,
          }}
          className={classNames(imageClassName)}
        />
        <div className="absolute bottom-0 h-full w-full rounded-lg bg-black-80deg-to-tr" />
        <div className="absolute bottom-0 h-full w-full rounded-lg bg-black-b-t" />
        <PaddedContainer className="hidden lg:absolute lg:bottom-12 lg:-ml-8 lg:block">{words}</PaddedContainer>
      </div>
      <PaddedContainer className="lg:hidden">{words}</PaddedContainer>
    </>
  )
}
